import React from 'react';
import './styles.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import Logo from '../../assets/logo.png'
import Detail from '../../assets/detailfooter.png'
import Content from '../Content';
import fisioterapia1 from '../../assets/fisioterapia1.jpg';
import fisioterapia2 from '../../assets/fisioterapia2.jpg';
import fisioterapia4 from '../../assets/fisioterapia4.jpg';
import treinamentofuncional1 from '../../assets/treinamentofuncional1.jpg';
import treinamentofuncional2 from '../../assets/treinamentofuncional2.jpg';
import treinamentofuncional3 from '../../assets/treinamentofuncional3.jpg';
import treinamentofuncional4 from '../../assets/treinamentofuncional4.jpg';
import fisioterapia5 from '../../assets/fisioterapia5.jpg';
import novatreinamentofuncional2 from '../../assets/novatreinamentofuncional2.jpg';
import novaentrada2 from '../../assets/novaentrada2.jpg';


export default function Footer(){
    return(
        <div className='full-footer'>
            <div className='estrutura-footer'>
                <Content
                    title='Nossa Estrutura'
                    medias={
                        [
                            {
                              src: fisioterapia1,
                              medianame: "Fisioterapia",
                            },
                            {
                              src: fisioterapia2,
                              medianame: "Fisioterapia",
                            },
                            {
                              src: fisioterapia4,
                              medianame: "Fisioterapia",
                            },
                            {
                              src: fisioterapia5,
                              medianame: "Fisioterapia",
                            },
                            {
                              src: novaentrada2,
                              medianame: "Recepção",
                            },
                            {
                              src: treinamentofuncional1,
                              medianame: "Treinamento Funcional",
                            },
                            {
                              src: treinamentofuncional2,
                              medianame: "Treinamento Funcional",
                            },
                            {
                              src: treinamentofuncional3,
                              medianame: "Treinamento Funcional",
                            },
                            {
                              src: treinamentofuncional4,
                              medianame: "Treinamento Funcional",
                            },
                            {
                              src: novatreinamentofuncional2,
                              medianame: "Treinamento Funcional",
                            },
                          ]
                    }
                />
            </div>
            <footer className='footer'>

                <div className='footer-decoration'>
                    <img src={Detail} alt=""/>
                </div>
                <div className='border'></div>
                <div className="social-container">
                    <a href="https://www.facebook.com/seucentrodesaudeintegrada/" title="facebook">
                        <FontAwesomeIcon icon={faFacebook} size="3x" className='fontawesome-icon' />
                    </a>
                    <a href="https://wa.me/5553981102405" title="whatsapp">
                      <FontAwesomeIcon icon={faWhatsapp} size="3x" className='fontawesome-icon' />
                    </a>
                    <a href="https://www.instagram.com/seu.life/" title="instagram">
                        <FontAwesomeIcon icon={faInstagram} size="3x" className='fontawesome-icon' />
                    </a>
                </div>
                
                <div className='info'>
                    <p>Av. Fernando Osório, 153 :: Centro</p>
                    <p>CEP 96020-151 :: Pelotas/RS</p>
                    <p>53 32289682 :: contato@seu.life</p>
                    <img src={Logo} alt="Logo" />
                    <div>
                      <p>Desenvolvido por</p><br></br>
                      <a target="_blank" rel="noopener noreferrer" href="https://brainny.cc/">Brainny Smart Solutions | 2020</a>
                    </div>
                </div>

                </footer>
        </div>

    );
}