import React, { useEffect } from 'react';
import HeaderPrimary from '../../components/HeaderPrimary';
import Content from '../../components/Content';
import Footer from '../../components/Footer';
import fisioterapia2 from '../../assets/fisioterapia2.jpg';
import Background from '../../assets/background-nutricao.png';


export default function Nutricao() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return(
        <div>
            <HeaderPrimary
                background={Background}
                backgroundMobile={Background}
                headline={'Nutrição'}
                paragraphs={[
                    {text: 'No trabalho de reeducação alimentar, é necessário <strong>dar atenção para as relações existentes entre alimentação, conforto e estresse</strong> que o paciente apresenta ao comer.'},
                ]}
            />
            <Content
                title={'Nutrição Esportiva'}
                paragraphs={[
                    {text: 'Elaborar um plano de alimentar é algo totalmente individual, haja vista que a diversidade humana é gigantesca. Para isso é necessário calcular a demanda energética respeitando as característica de cada paciente. Quando falamos de atletas ou desportistas a demanda energética é ainda mais específica visto que além do gasto considerado “normal” é necessário saber sobre cada modalidade esportiva e sua intensidade dentro da rotina deste paciente!'},
                    {text: 'O diferencial da Nutrição esportiva é o fato de que a nutricionista está familiarizada com os hábitos relacionados ao dia a dia do atleta levando sempre em consideração o sonho, calendário, esportes, personalidades, aspectos bioquímicos, metabólicos e fisiológicos.'},
                    {text: 'Para obter o sucesso dos objetivos e boa performance é fundamental acompanhar a composição do corporal do paciente, pois assim é possível ajustar os cardápios de acordo com a demanda de macro e micronutriente, desta maneira podemos melhorar a performance esportiva, ter mais qualidade nos treinos de atletas e qualidade de vida de desportista e também minimizar ou até evitar lesões!'},
                ]}
                medias={[
                    {
                      src: fisioterapia2,
                      medianame: "Espaço de Nutrição",
                    },
                ]}
                button={{
                    text: 'agende sua consulta',
                    link: '/contato'
                }}
            />
            <Footer />
        </div>
    );
}