import React from 'react';
import './App.css';
import Routes from './routes';
import {Helmet} from "react-helmet";
import logo from '../src/assets/logocinza.png';

function App() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SEU</title>
        <link rel="canonical" href="example.com" />
        <meta name="robots" content="index, nofollow"/>

        {/* <!-- Google+ / Schema.org --> */}
        <meta itemProp="name" content="SEU - Centro de Saúde Integrada"/>
        <meta itemProp="description" content="Nosso objetivo, através do SEU - Centro de Saúde Integrada é trazer ao usuário um acompanhamento completo, que considera alimentação, atividades físicas e fisioterápicas como complementares."/>
        <meta itemProp="image" content={logo}/>
        <link href={process.env.REACT_APP_BASE_URL} rel="publisher"/>
        
        {/* <!-- Open Graph Facebook --> */}
        <meta property="og:title" content="SEU - Centro de Saúde Integrada"/>
        <meta property="og:description" content="Nosso objetivo, através do SEU - Centro de Saúde Integrada é trazer ao usuário um acompanhamento completo, que considera alimentação, atividades físicas e fisioterápicas como complementares."/>
        <meta property="og:url" content={process.env.REACT_APP_BASE_URL}/>
        <meta property="og:site_name" content="SEU - Centro de Saúde Integrada"/>
        <meta property="og:type" content="website"/>
        <meta property="og:image" content={logo}/>

        {/* <!-- Twitter --> */}
        <meta name="twitter:title" content="SEU - Centro de Saúde Integrada"/>
        <meta name="twitter:description" content="Nosso objetivo, através do SEU - Centro de Saúde Integrada é trazer ao usuário um acompanhamento completo, que considera alimentação, atividades físicas e fisioterápicas como complementares."/>
        <meta name="twitter:url" content={process.env.REACT_APP_BASE_URL}/>
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:image" content={logo}/>
      </Helmet>
      <Routes />
    </div>
    
  );
}

export default App;
