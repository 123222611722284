import React from 'react';
import Logo from '../../assets/logo.png';
import './styles.css';
import Menu from '../Menu';
import Content from '../Content';
import { useHistory } from 'react-router-dom';


interface Paragraph {
    text: string;
}

interface HeaderPrimary {
    headline?: string;
    paragraphs?: Paragraph[];
    background?: string;
    backgroundSize?: string;
    backgroundMobile?: string;
}


export default function HeaderPrimary(props: HeaderPrimary) {
    const history = useHistory();
    return(
        <div className='header-body'>
            <div>
                <div className={`header-primary`} style={{backgroundImage: `url(${props.background})`, backgroundSize: `${props?.backgroundSize}`}}>
                    <img className='logo' src={Logo} alt="Logo" onClick={() => history.push('/')}/>
                    <Menu />
                </div>
                <div className={`header-primary-mobile`} style={{backgroundImage: `url(${props.backgroundMobile})`, backgroundSize: `${props?.backgroundSize}`}}>
                    <img className='logo' src={Logo} alt="Logo" onClick={() => history.push('/')}/>
                    <Menu />
                </div>
                <div className='header-primary-box'>
                    <Content
                        headline={props?.headline}
                        paragraphs={props?.paragraphs}
                    />
                </div>
            </div>
        </div>

    );
}