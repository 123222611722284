import React, { useEffect } from 'react';
import HeaderPrimary from '../../components/HeaderPrimary';
import Content from '../../components/Content';
import Footer from '../../components/Footer';
import fisioterapia5 from '../../assets/fisioterapia5.jpg';
import fisioterapia2 from '../../assets/fisioterapia2.jpg';
import fisioterapia4 from '../../assets/fisioterapia4.jpg';
import Background from '../../assets/background-fisioterapia.png';

export default function Fisioterapia() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return(
        <div>
            <HeaderPrimary
                background={Background}
                backgroundMobile={Background}
                headline={'Fisioterapia'}
                paragraphs={[
                    {text: '<strong>A avaliação fisioterápica é a chave na condução de um tratamento</strong>, onde o fisioterapeuta pode explorar o atual estado físico do paciente, tornando compreensível o que ele diz e demonstra sobre a sua lesão ou patologia.'},
                ]}
            />
            <Content
                title={'Diagnóstico'}
                paragraphs={[
                    {text: 'Os distúrbios muscoloesqueléticos serão avaliados nos aspectos da <strong>análise da função articular (mobilidade e força muscular) e seu funcionamento</strong>, mensurados e quantificados, complementando a anamnese e o diagnóstico médico.'},
                    {text: '<strong>A tal conduta não visa em nenhum momento realizar o diagnóstico médico, mas sim, o fisioterapêutico</strong>, que engloba aspectos e características particulares, e que está baseado na anatomia, biomecânica, cinesionogia e fisiologia para determinar as possibilidades de etiologia do problema.'}
                ]}
                medias={[
                    {
                      src: fisioterapia2,
                      medianame: "Espaço Fisioterapia",
                    },
                    {
                      src: fisioterapia4,
                      medianame: "Espaço Fisioterapia",
                    },
                    {
                        src: fisioterapia5,
                        medianame: "Espaço Fisioterapia",
                    },
                  ]}
            />
            <Content
                paragraphs={[
                    {text: 'Tendo em vista a individualidade biológica de cada ser humano, não consideramos que todos os pacientes, com distúrbios semelhantes, sejam enquadrados em um modelo padrão de abordagem terapêutica.'},
                    {text: '<strong>O objetivo de uma avaliação inicial em nosso serviço é buscar o diagnóstico diferencial</strong>, ou seja, identificar outras vertentes que levam à queixa do paciente, considerando as atividades exercidas durante seu trabalho, lazer ou mesmo esportiva.'}
                ]}
                button={{
                    text: 'agende sua consulta',
                    link: '/contato'
                }}
            />
            <Footer />
        </div>
    );
}