import React, { useEffect, useState } from 'react';
import './styles.css';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useHistory } from 'react-router-dom';
import Aos from 'aos';
import "aos/dist/aos.css";

interface Paragraph {
    text: string;
}

interface Button {
    text: string;
    link: string;
}

interface Media {
    src: string;
    medianame?: string;
    legend?: string;
    link?: string;
  }

interface Content {
    headline?: string;
    title?: string;
    paragraphs?: Paragraph[];
    medias?: Media[];
    button?: Button;
    bulletList?: Paragraph[];
}


export default function Content(props: Content) {

    const history = useHistory<string>();
    const [buttonLink, setButtonLink] = useState('/contato');

    useEffect(() => {
        if (props.button?.link !== undefined) {
            setButtonLink(props.button.link);
        }       
    }, [props.button]);

    useEffect(() => {
        Aos.init({duration: 1500});
      }, [])


    return(
        <div className='body'>
            {props?.headline &&
            <div className='headline'>
                {props?.headline && <h1>{props.headline}</h1>}
            </div>}
            

            {props?.title && <h2>{props.title}</h2>}
            
            {(props?.paragraphs || props?.bulletList ) && 
                <div className="card"  data-aos="fade">
                    <div>
                        {props?.paragraphs?.map((paragraph: Paragraph) => (
                            <p dangerouslySetInnerHTML={{__html: paragraph.text}}></p>
                            // <p>{paragraph.text}</p>
                        ))}
                        {props?.bulletList?.map((list: Paragraph) => (
                            <li>{list.text}</li>
                        ))}
                    </div>
                </div>
            }
            {props?.medias &&
            <div className='carousel-card' data-aos="fade">
                <Carousel showThumbs={false} showArrows={true} >
                    {props?.medias?.map((media: Media) => (
                        <div className='carousel'>
                            <img src={media.src} alt={media.medianame}  />
                            {/* <img src={media.src} alt={media.medianame} onClick={() => history.push(media.link)}/> */}
                            {/* {media.legend && <p className="legend">{media.legend}</p>} */}
                        </div>
                    ))}
                </Carousel>
            </div>}
            
            {/* {props.button?.text && <button onClick={() => history.push(props.button.link)}>{props.button.text}</button>} */}
            {props.button?.text && <button onClick={() => history.push(buttonLink)}>{props.button.text}</button>}
        </div>
    );
}