import React, { useEffect } from 'react';
import './styles.css';
import Footer from '../../components/Footer';
import HeaderPrimary from '../../components/HeaderPrimary';
import Content from '../../components/Content';
import Logo from '../../assets/logocinza.png'
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import Background from '../../assets/background-contatos.png';
import backgroundMobile from '../../assets/background-contatos-mobile.png';


const Contato = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div className='contato-body'>
                <HeaderPrimary
                    background={Background}
                    backgroundMobile={backgroundMobile}
                    backgroundSize={'100% 100%'}
                    headline={'Contato'}
                />
            <div id="contato">
                <fieldset>
                    <Map center={[-31.749097, -52.340879]} zoom={16}>
                    <TileLayer attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                        <Marker position={[-31.749097, -52.340879]}>
                            <Popup>
                                <img className='map-image' src={Logo} alt="SEU"/>
                                <p>Centro de Saúde Integrada</p>
                            </Popup>
                        </Marker>
                    </Map>
                    <div className='bottom-map'>
                        <h3>Visite-nos</h3>
                        <p>Av. Fernando Osório, 153 :: Centro</p>
                        <p>CEP 96020-151 :: Pelotas/RS</p>
                    </div>
                </fieldset>
            </div>
            <Content
                title={'Fale conosco'}
            />
                <div className='contact-buttons'>
                    <button className='contact-button' onClick={() => (window.open("https://wa.me/5553981102405"))}>
                    <FontAwesomeIcon icon={faWhatsapp} size="1x" /><strong> 53 </strong> 98110-2405</button>
                    
                    <a href={`tel:+555332289682`}>
                        <button className='contact-button'>
                        <FontAwesomeIcon icon={faPhone} size="1x" /><strong> 53 </strong>3228-9682</button>
                    </a>
                    <a href={`mailto:contato@seu.life`}>
                        <button className='contact-button'>
                        <FontAwesomeIcon icon={faEnvelope} size="1x" /> contato@seu.life</button>
                    </a>
                </div>
            <Footer />
        </div>
    );
}
export default Contato;