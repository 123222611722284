import React, { useEffect } from 'react';
import HeaderPrimary from '../../components/HeaderPrimary';
import Content from '../../components/Content';
import Footer from '../../components/Footer';
import treinamentofuncional1 from '../../assets/treinamentofuncional1.jpg';
import treinamentofuncional2 from '../../assets/treinamentofuncional2.jpg';
import treinamentofuncional3 from '../../assets/treinamentofuncional3.jpg';
import treinamentofuncional4 from '../../assets/treinamentofuncional4.jpg';
import treinamentofuncional5 from '../../assets/novatreinamentofuncional2.jpg';
import Background from '../../assets/background-treinamentofuncional.png';

export default function TreinamentoFuncional() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return(
        <div>
            <HeaderPrimary
                background={Background}
                backgroundMobile={Background}
                headline={'Treinamento Funcional'}
                paragraphs={[
                    {text: 'O treinamento funcional é um método de trabalho dinâmico. Seu diferencial é <strong>mesclar diferentes capacidades físicas em um único exercício.</strong>'},
                    {text: 'Assim, o <strong>foco passa de um grupo muscular isolado para todo o corpo</strong> - os movimentos trabalham a força muscular, a flexibilidade, o sistema cardiorrespiratório, a coordenação motora e o equilíbrio, simultaneamente.'},
                ]}
            />
            <Content
                title={'Benefícios'}
                bulletList={[
                    {text: 'O programa se adapta a você e as suas necessidades;'},
                    {text: 'Você adquire saúde, performance e o corpo dos seus sonhos;'},
                    {text: 'Altamente motivador pela quantidade infinita de exercícios e possibilidades;'},
                    {text: 'Melhora da postura geral durante os exercícios;'},
                    {text: 'Aumento de força muscular.'}
                ]}
                medias={[
                    {
                      src: treinamentofuncional3,
                      medianame: "Espaço Treinamento Funcional",
                    },
                    {
                      src: treinamentofuncional1,
                      medianame: "Espaço Treinamento Funcional",
                    },
                    {
                      src: treinamentofuncional2,
                      medianame: "Espaço Treinamento Funcional",
                    },
                    {
                      src: treinamentofuncional4,
                      medianame: "Espaço Treinamento Funcional",
                    },
                    {
                      src: treinamentofuncional5,
                      medianame: "Espaço Treinamento Funcional",
                    },
                ]}
                button={{
                    text: 'agende sua aula',
                    link: '/contato'
                }}
            />
            <Footer />
        </div>
    );
}