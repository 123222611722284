import React, { useEffect } from 'react';
import HeaderPrimary from '../../components/HeaderPrimary';
import Content from '../../components/Content';
import Footer from '../../components/Footer';
import './style.css';
// import equipe1 from '../../assets/equipe1.jpg';
// import equipe2 from '../../assets/equipe2.jpg';
// import equipe3 from '../../assets/equipe3.jpg';
// import equipe4 from '../../assets/equipe4.jpg';
// import equipe5 from '../../assets/equipe5.jpg';
// import equipe6 from '../../assets/equipe6.jpg';
// import equipe7 from '../../assets/equipe7.jpg';
import Background from '../../assets/background-sobre.jpg';
import backgroundMobile from '../../assets/background-sobre-mobile.jpg';

export default function Sobre() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return(
        <div className='sobre'>
            <HeaderPrimary
                background={Background}
                backgroundMobile={backgroundMobile}
                backgroundSize={'100% 100%'}
                headline={'Sobre'}
                paragraphs={[
                    {text: 'Nosso objetivo, através do <strong>SEU - Centro de Saúde Integrada</strong> é trazer ao usuário um <strong>acompanhamento completo</strong>, que considera alimentação, atividades físicas e fisioterápicas como complementares.'},
                    {text: 'Nossos profissionais são comprometidos a sempre dialogar de forma <strong>multidisciplinar e construtiva</strong>, relacionando cada característica do paciente e tornando sua qualidade de vida cada vez mais elevada.'},
                ]}
            />
            <div className ='equipe'>
                <div className ='equipe-info'>
                    <div className='equipe-info-text'>
                        <div className="vl"></div>
                        <div>
                            <h2>A equipe conta hoje com:</h2>
                        </div>
                    </div>
                    <div className='equipe-info-text'>
                        <h1 className='numberCircle'>5</h1>
                        <h3>fisioterapeutas</h3>
                    </div>
                    <div className="vl-number"></div>
                    <div className='equipe-info-text'>
                        <h1 className='numberCircle'>2</h1>
                        <h3>educadores físicos</h3>
                    </div>
                    <div className="vl-number"></div>
                    <div className='equipe-info-text'>
                        <h1 className='numberCircle'>1</h1>
                        <h3>nutricionista</h3>
                    </div>
                    
                    {/* <div className="vl-number"></div>
                    <div className='equipe-info-text'>
                        <h1 className='numberCircle'>1</h1>
                        <h3>psicóloga</h3>
                    </div> */}
                </div>
            </div>
            {/* <Content
                medias={[
                    {
                    src: equipe1,
                    medianame: " ",
                    },
                    {
                    src: equipe2,
                    medianame: " ",
                    },
                    {
                    src: equipe3,
                    medianame: " ",
                    },
                    {
                    src: equipe4,
                    medianame: " ",
                    },
                    {
                    src: equipe5,
                    medianame: " ",
                    },
                    {
                    src: equipe6,
                    medianame: " ",
                    },
                    {
                    src: equipe7,
                    medianame: " ",
                    },
                ]}
            /> */}
            <Content

                paragraphs={[
                    {text: 'Nossa equipe atende sob horário agendado e monitoram resultados periodicamente, focados em otimizar a qualidade de vida de seus pacientes/alunos.'}
                ]}
                button={{
                    text: 'entre em contato',
                    link: '/contato'
                }}
            />
            <Footer />
        </div>
    );
}