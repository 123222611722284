import React, {useEffect} from 'react';
import Content from '../../components/Content';
import Footer from '../../components/Footer';
import HeaderSecondary from '../../components/HeaderSecondary';
import './styles.css';
import treinamentofuncional from '../../assets/Treinamento_Funcional.png';
import fisioterapia from '../../assets/Fisioterapia.png';
import nutricao from '../../assets/Nutricao.png';
import pilates from '../../assets/Pilates.png';
import { useHistory } from 'react-router-dom';
import BackgroundMobile from '../../assets/background-home-desktop.png';
import Aos from 'aos';
import "aos/dist/aos.css";




export default function Home() {
  const history = useHistory();
  useEffect(() => {
    Aos.init({duration: 1500});
  }, [])
  return (
      <div className='home'>

        
        <HeaderSecondary
          background={BackgroundMobile}
        />
        
        <Content
            title={'Sobre'}
            paragraphs={[
              { text: "Nosso objetivo, através do <strong>SEU - Centro de Saúde Integrada</strong> é trazer ao usuário um <strong>acompanhamento completo</strong>, que considera alimentação, atividades físicas e fisioterápicas como complementares." },
            ]}
            button={{
              text: 'saiba mais',
              link: '/sobre'
          }}
        />
        
        <Content
            title={'Serviços'}
        />
        <div className='home-services' data-aos="fade">
          <div className='service'>
            <div onClick={() => history.push('/fisioterapia')}>
              <span>
                <img className='service-image' src={fisioterapia} alt="Fisioterapia"/>
              </span>
              <span>
                <p className='service-text'>Fisioterapia</p>
              </span>
            </div>

          </div>
          <div className='service'>
            <div onClick={() => history.push('/treinamentofuncional')} style={{marginTop: '15px'}}>
              <span>
                <img className='service-image' src={treinamentofuncional} alt="Treinamento Funcional"/>
              </span>
              <span>
                <p className='service-text'>Treinamento Funcional</p>
              </span>
              
            </div>

          </div>
          <div className='service'>
            <div onClick={() => history.push('/nutricao')}>
              <span>
                <img className='service-image' src={nutricao} alt="Nutrição"/>
              </span>
              <span>
                <p className='service-text'>Nutrição</p>
              </span>
              
            </div>

          </div>
          <div className='service'>
            <div onClick={() => history.push('/pilates')}>
              <span>
                <img className='service-image' src={pilates} alt="Pilates"/>
              </span>
              <span>
                <p className='service-text'>Pilates</p>
              </span>     
            </div>

          </div>
          {/* <div className='service'>
            <div onClick={() => history.push('/psicologia')}>
              <span>
                <img className='service-image' src={psicologia} alt="Psicologia"/>
              </span>
              <span>
                <p className='service-text'>Psicologia</p>
              </span>
            </div>

          </div> */}
        </div>
        

        <Footer />
        
      </div>

  );
}

