import React from 'react'
import { Route, Router } from 'react-router-dom';
import Home from './pages/Home';
import Sobre from './pages/Sobre';
import Fisioterapia from './pages/Fisioterapia';
import TreinamentoFuncional from './pages/TreinamentoFuncional';
import Nutricao from './pages/Nutricao';
import Pilates from './pages/Pilates';
// import Psicologia from './pages/Psicologia';
import Contato from './pages/Contato';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';


const Routes = () => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID ? process.env.REACT_APP_GOOGLE_ANALYTICS_ID : "");
    const history = createBrowserHistory();
    // Initialize google analytics page view tracking
    history.listen(location => {
        ReactGA.set({ page: location.pathname }); // Update the user's current page
        ReactGA.pageview(location.pathname); // Record a pageview for the given page
    });
    return (
        <Router history={history}>
            <Route component={Home} path="/" exact={true} />
            <Route component={Sobre} path="/sobre" />
            <Route component={Fisioterapia} path="/fisioterapia" />
            <Route component={TreinamentoFuncional} path="/treinamentofuncional" />
            <Route component={Nutricao} path="/nutricao" />
            <Route component={Pilates} path="/pilates" />
            {/* <Route component={Psicologia} path="/psicologia" /> */}
            <Route component={Contato} path="/contato" />
        </Router>
    );
}

export default Routes;