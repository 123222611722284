import React, { useState } from 'react';
import './styles.css';
import { useHistory } from 'react-router-dom';
import LogoMenu from '../../assets/logocinza.png';



export default function Menu() {
    const history = useHistory();
    const [visible, setVisible] = useState(false);
    const [visibleVertical, setVisibleVertical] = useState(false);
    const [menuBtn, setMenuBtn] = useState('');
    const [arrow, setArrow] = useState('arrow down');


    
    function handleVerticalToggle() {
        setVisibleVertical(!visibleVertical)
        if (arrow === 'arrow down') {
            setArrow('arrow up');
        } else {
            setArrow('arrow down');
        }
    }
    
    function handleToggle() {
        if (menuBtn === '') {
            setMenuBtn('open');
            setVisible(!visible)
        } else {
            setMenuBtn('');
            setVisible(!visible)
        }
    }


        
    return(
        <div>
            
            <div className="menu">
                
                <button className='primary-menu-button' onClick={() => history.push('/sobre')}>Sobre</button>
                <button className='dropbtn primary-menu-button'>Serviços
                    <div className="dropdown-content">
                        <button className='secondary-menu-button' onClick={() => history.push('/fisioterapia')}>Fisioterapia</button>
                        <button className='secondary-menu-button' onClick={() => history.push('/pilates')}>Pilates</button>
                        <button className='secondary-menu-button' onClick={() => history.push('/treinamentofuncional')}>Treinamento Funcional</button>
                        <button className='secondary-menu-button' onClick={() => history.push('/nutricao')}>Nutrição</button>
                        {/* <button className='secondary-menu-button' onClick={() => history.push('/psicologia')}>Psicologia</button> */}
                    </div>
                </button>
                <button className='primary-menu-button' onClick={() => history.push('/contato')}>Contato</button>
                
                <div className={`menu-btn ${ menuBtn }`}  onClick={(handleToggle)}>
                    <div className="menu-btn__burger"></div>
                </div>

                {visible ?            
                <div className='vertical-menu'>
                    <div>
                        <img className='logo' src={LogoMenu} alt="Logo" onClick={() => history.push('/')}/>
                    </div>
                    <div className='vertical-menu-btn'>
                        <button className='vertical-primary-menu-button' onClick={() => history.push('/sobre')}>Sobre</button>
                        <button className='vertical-primary-menu-button' onClick={(handleVerticalToggle)}>Serviços
                        <i className={`${ arrow }`}></i>
                        </button>
                            {visibleVertical? 
                            <div className="vertical-dropdown-content">
                                <button className='vertical-secondary-menu-button' onClick={() => history.push('/fisioterapia')}>Fisioterapia</button>
                                <button className='vertical-secondary-menu-button' onClick={() => history.push('/pilates')}>Pilates</button>
                                <button className='vertical-secondary-menu-button' onClick={() => history.push('/treinamentofuncional')}>Treinamento Funcional</button>
                                <button className='vertical-secondary-menu-button' onClick={() => history.push('/nutricao')}>Nutrição</button>
                                {/* <button className='vertical-secondary-menu-button' onClick={() => history.push('/psicologia')}>Psicologia</button> */}
                            </div>: null}
                        
                        <button className='vertical-primary-menu-button' onClick={() => history.push('/contato')}>Contato</button>
                    </div>

                </div> : null} 
            </div>
        </div>
    );
}