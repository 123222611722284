import React, { useEffect } from 'react';
import Logo from '../../assets/logo.png';
import aspas from '../../assets/aspas.png';
import Aos from 'aos';
import "aos/dist/aos.css";
import Fisio from '../../assets/fisio.png';
import './styles.css';
import Menu from '../Menu';
import { useHistory } from 'react-router-dom';

interface Header {
    desktopHeader?: string;
    background?: string;
    backgroundSize?: string;
}

export default function HeaderSecondary(props: Header) {
    useEffect(() => {
        Aos.init({duration: 1500});
    }, [])
    const history = useHistory();
    return(
        <div className='header-body'>
            <div>
                <div className={`full-header-secondary ${ props?.desktopHeader }`}
                    style={{backgroundImage: `url(${props.background})`,
                    backgroundSize: `${props?.backgroundSize}`}}>
                <div className='header-secondary'>
                    <img className='logo' src={Logo} alt="Logo" onClick={() => history.push('/')}/>
                    <Menu />
                </div>
                <div>
                    <section className='header-main'>
                        <p>centro</p>
                        <p>de saúde</p>
                        <p>integrada</p>
                    </section>
                </div>
                </div>


                <div className='header-text' data-aos="fade">
                    <div>
                        <img className='card-aspas' src={aspas} alt="Aspas" />
                        <span className='header-message'>
                            <p>O corpo humano precisa estar equilibrado em todas as suas esferas para que funcione perfeitamente.</p>
                        </span>
                        <span className='header-fisio'>
                            <img className='image-fisio' src={Fisio} alt="Paula Nunes"/>
                            <span>
                                <p>Paula Nunes</p>
                                <h4>Fisioterapeuta</h4>
                                <h4>CREFITO: 10357-LTT/F</h4>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>

    );
}