import React, { useEffect } from 'react';
import HeaderPrimary from '../../components/HeaderPrimary';
import Content from '../../components/Content';
import Footer from '../../components/Footer';
import fisioterapia1 from '../../assets/fisioterapia1.jpg';
import Background from '../../assets/background-pilates.png'


export default function Pilates() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return(
        <div>
            <HeaderPrimary
                background={Background}
                backgroundMobile={Background}
                headline={'Pilates'}
                paragraphs={[
                    {text: 'O pilates é um método de exercício físico e alongamento que utiliza o peso do próprio corpo na sua execução.'},
                    {text: 'É uma técnica de reeducação do movimento que visa trabalhar o corpo todo, trazendo equilíbrio muscular e mental.'}
                ]}
            />
            <Content
                title={'Benefícios'}
                bulletList={[
                    {text: 'Alongar, tonificar e definir a musculatura sem exageros;'},
                    {text: 'Melhorar a postura;'},
                    {text: 'Prevenir e recuperar lesões;'},
                    {text: 'Reduzir o "stress" e aliviar as tensões'},
                    {text: 'Alinhar os músculos, deixar sua coluna mais forte e flexível;'},
                    {text: 'Melhorar a circulação sanguínea e o movimento das articulações;'},
                    {text: 'Aumentar a coordenação e o equilíbrio;'},
                    {text: 'Melhorar o visual de seu corpo e a sua auto-estima;'},
                    {text: 'Alívio de dores.'},
                ]}
                medias={[
                    {
                      src: fisioterapia1,
                      medianame: "Espaço Pilates",
                    },
                ]}
                button={{
                    text: 'agende sua consulta',
                    link: '/contato'
                }}
            />
            <Footer />
        </div>
    );
}